<template>
  <div class="cms-pages">
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <!-- <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div>
          </b-col> -->
          <b-col lg="12">
            <h1>{{ cmsPageContent.title }}</h1>
            <!--<div v-html="cmsPageContent.content" />-->
            <VRuntimeTemplate
              v-if="cmsPageContent.content"
              :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
            ></VRuntimeTemplate>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
//import SidebarLinks from "@/base/core/components/SidebarLinks";
import VRuntimeTemplate from "v-runtime-template";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "StaticPage",
  components: {
    Breadcrumbs,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    Logger.debug("metaInfo", "static", config)();
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
      link: [
        {
          rel: "canonical",
          href: config.app_url + this.$route.fullPath,
        },
      ],
    };
  },
  data: () => ({
    sidebarData: {
      heading: "Categorie",
      options: [
        { displayName: "Gentlemen", linkTo: "#", active: false },
        { displayName: "Women", linkTo: "#", active: false },
        { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
        { displayName: "Children", linkTo: "#", active: false },
        { displayName: "Cycling shoes", linkTo: "#", active: false },
        { displayName: "For your bike", linkTo: "#", active: false },
        { displayName: "For your body", linkTo: "#", active: false },
        { displayName: "Brands", linkTo: "#", active: false },
        { displayName: "Cycling books", linkTo: "#", active: false },
        { displayName: "Gift voucher", linkTo: "#", active: false },
      ],
    },
  }),
};
</script>
<style lang="scss">
.cms-content {
  padding-bottom: 50px;
  b,
  strong {
    font-weight: 500;
  }
  table {
    @media screen and (max-width: 670px) {
      max-width: 100vw;
      width: 100% !important;
      td {
        display: block;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
ul {
  li {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 1px;
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.mb-lg-27 {
  margin-bottom: 27px;
}

.font-weight-medium {
  font-weight: 500;
}
.sidebar {
  background: $grayColor;
  padding: 11px 26px 16px;
}

.sidebar-links {
  list-style: none;
  padding: 3px 0;
  margin: 0;

  li {
    + li {
      margin-top: 6px;
    }
  }

  a {
    position: relative;

    &.active {
      font-weight: 500;
      &:before {
        content: "";
      }
    }

    &:before {
      content: none;
      position: absolute;
      top: -1px;
      left: -24px;
      width: 3px;
      height: 29px;
      background: $red;
    }

    &:hover {
      color: #000 !important;
      font-weight: 500;
      &:before {
        content: "";
      }
    }
  }
}

.sidebar-heading {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 7px;
}
</style>

